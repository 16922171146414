<template>
  <div v-if="(allows_crud && !preview_evaluation) || evaluator_view">
    <div>
      <h3>Tabla de distribución de puntaje</h3>
    </div>
    <div
      class="container-matching-question-score mt-3 mb-5"
      :class="{
        'container-under-5': matchingQuestionOptionsTop.length < 5,
        'container-above-eight': matchingQuestionOptionsTop.length >= 8,
      }"
    >
      <b-table-simple class="term-table">
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th
              class="item-column"
              v-for="matching_question_top in matchingQuestionOptionsTop"
              :key="matching_question_top.id"
            >
              {{ matching_question_top.order }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="matching_question_left in matchingQuestionOptionsLeft"
            :key="matching_question_left.id"
          >
            <b-td class="item-row p-2">
              {{
                String.fromCharCode(
                  96 + matching_question_left.order
                ).toUpperCase()
              }}
            </b-td>
            <b-td
              v-for="matching_question_top in matchingQuestionOptionsTop"
              :key="matching_question_top.id + '-' + matching_question_left.id"
            >
              <MatchingQuestionScoreInput
                :matching_question_top="matching_question_top.id"
                :matching_question_left="matching_question_left.id"
                :Question="Question"
                :preview_evaluation="preview_evaluation"
                :evaluator_view="evaluator_view"
                :allows_crud="allows_crud"
              >
              </MatchingQuestionScoreInput>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
// import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "MatchingQuestionScore",
  components: {
    MatchingQuestionScoreInput: () => import("./MatchingQuestionScoreInput"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      matching_question_options: names.NEW_MATCHING_QUESTION_OPTIONS,
    }),
    matchingQuestionOptionsTop() {
      return this.matching_question_options
        .filter(
          (x) =>
            x.matching_question == this.Question.id &&
            x.display_to_the_left == false
        )
        .sort((a, b) => a.order - b.order);
    },
    matchingQuestionOptionsLeft() {
      return this.matching_question_options
        .filter(
          (x) =>
            x.matching_question == this.Question.id &&
            x.display_to_the_left == true
        )
        .sort((a, b) => a.order - b.order);
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.input-number {
  --bottom-border-color: var(--kl-menu-color);
  max-width: 90%;
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
.input-number:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
.term-table th {
  background-color: #f2f2f2;
}
.term-table th,
.term-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}
.item-row,
.item-column {
  background-color: #f2f2f2;
}
.item-column {
  padding-right: 16px !important;
}
.container-matching-question-score {
  margin-inline: 15%;
}
.container-above-eight {
  margin-inline: 5%;
}
.container-under-5 {
  margin-inline: 25%;
}
</style>